<script>
import { authMethods, notificationMethods } from "@/state/helpers";
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { errorMessages } from "@/helpers/validations/errorMessages";
// import ConfirmButton from "../../../components/buttons/confirm-button.vue";

export default {
    // components: { ConfirmButton },
    data() {
        return {
            password: "",
            repassword: "",
            email: "",
            token: "",
            submitted: false,
            errorMsg: null,
            tryingToReset: false,
            isResetError: false,
            loading: false,
            errorMessages,
        };
    },
    validations: {
        password: { required, minLength: minLength(8), maxLength: maxLength(20) },
        repassword: { required, minLength: sameAs('password') },
    },
    async created() {
        document.body.classList.add("auth-body-bg");
    },
    async mounted() {
        try {
            const params = this.$route.query;
            this.email = params.email;
            this.token = params.token;
            await this.validateResetToken({
                email: params.email,
                token: params.token
            });
        }
        catch (error) {
            this.errorMsg = error?.response?.data?.errors[0]?.title;
            this.error(this.errorMsg);
            this.$router.push({ name: "login" });
        }
    },
    methods: {
        ...authMethods,
        ...notificationMethods,
        // Try to register the user in with the email, fullname
        // and password they provided.
        async tryToReset() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;
            this.tryingToReset = true;
            // Reset the authError if it existed.
            this.errorMsg = null;
            try {
                await this.resetPassword({
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    re_password: this.repassword,
                });
                this.loading = false;
                this.success("Contraseña cambiada correctamente.");
                this.$router.push({ name: "login" });
            }
            catch (error) {
                this.loading = false;
                console.log(error);
            }
        }
    }    
};
</script>

<template>
    <div>
        <div class="home-btn d-none d-sm-block">
            <a href="/">
                <i class="mdi mdi-home-variant h2 text-white"></i>
            </a>
        </div>
        <div>
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-4">
                        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div class="w-100">
                                <div class="row justify-content-center">
                                    <div class="col-lg-9">
                                        <div>
                                            <div class="text-center">
                                                <div class=" mb-3">
                                                    <a href="/" class="logo">
                                                        <img src="@/assets/images/banner-cabecera.jpg" height="20" alt="logo" />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href="/" class="logo">
                                                        <img src="@/assets/images/logo-iDEAFP.png" height="60" alt="logo" />
                                                    </a>
                                                </div>

                                                <h4 class="font-size-18 mt-4">Resturar contraseña</h4>
                                                <p class="text-muted">Restaurar contraseña para iDEAFP</p>
                                            </div>

                                            <div class="p-2 mt-5">
                                                <b-alert
                                                    variant="danger"
                                                    class="mt-3"
                                                    v-if="errorMsg"
                                                    show
                                                >{{errorMsg}}</b-alert>

                                                <form class="form-horizontal" @submit.prevent="tryToReset">
                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <label for="password">Contraseña</label>
                                                        <input v-model="password" type="password" class="form-control" id="password" placeholder="Introduce contraseña" :class="{ 'is-invalid': submitted && $v.password.$error }"/>
                                                        <div v-if="submitted && $v.password.$error" class="invalid-feedback">
                                                            <span v-if="!$v.password.required">{{ errorMessages.required }}</span>
                                                            <span v-else-if="!$v.password.minLength">Debe tener al menos {{ $v.password.$params.minLength.min }} caracteres.</span>
                                                            <span v-else-if="!$v.password.maxLength">No puede tener más de {{ $v.password.$params.maxLength.max }} caracteres.</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <label for="repassword">Confirma Contraseña</label>
                                                        <input v-model="repassword" type="password" class="form-control" id="repassword" placeholder="Confirma Contraseña" :class="{ 'is-invalid': submitted && $v.repassword.$error }"/>
                                                        <div v-if="submitted && $v.repassword.$error" class="invalid-feedback" >
                                                            <div v-if="!$v.repassword.required">{{ errorMessages.required }}</div>
                                                            <div v-else-if="!$v.repassword.sameAs">{{ errorMessages.sameAs }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        <button
                                                            class="btn btn-primary w-md waves-effect waves-light"
                                                            type="submit"
                                                            :disabled="loading"
                                                            ><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                                        Generar</button>
                                                    </div>
                                                </form>
                                            </div>

                                            <div class="mt-5 text-center">
                                                <p>                                                    
                                                    <router-link tag="a" to="/login"
                                                        class="font-weight-medium text-primary">Log in</router-link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="authentication-bg">
                            <div class="bg-overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
